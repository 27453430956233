<template>
  <div class="pt-2">
    <v-row v-if="filtersReady">
      <component
        v-for="(item, index) in selectedSourceLogFilters"
        :key="index"
        :is="item"
        :items="parsingTypes"
        :statuses="parsingStatus"
        :label="availableSourceLogFilters[item]"
        v-model="filters[item]"
        @remove="removeFilter(item)"
      ></component>
      <v-col
        :sm="isEmpty(availableFilters) ? 6 : 12"
        :md="isEmpty(availableFilters) ? 4 : 8"
        :lg="isEmpty(availableFilters) ? 2 : 4"
        class="align-self-center"
      >
        <v-menu
          v-if="!isEmpty(availableFilters)"
          top
          :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              outlined
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              <v-icon x-small>mdi-plus</v-icon>
              Add filter
            </v-btn>
          </template>

          <v-list width="250px">
            <v-list-item-group>
              <v-virtual-scroll
                height="200"
                :itemHeight="40"
                :items="availableFilters"
              >
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-list-item-title @click="addFilter(item.key)">{{
                      item.label
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn
          x-small
          outlined
          color="grey darken-1"
          dark
          @click="resetFilters"
        >
          reset Filters
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import TextFilter from "../../components/searchParts/TextFilter";
import DateRangeFilter from "../../components/searchParts/DateRangeFilter";
import EnumFilter from "../../components/searchParts/EnumFilter";
import StatusEnumFilter from "../../components/searchParts/StatusEnumFilter";
import ChannelFilter from "../../components/searchParts/ChannelFilter";

import { mapGetters } from "vuex";
import { emptyFilters } from "@/store/modules/logs/state";
import { SET_EMPTY_FILTERS } from "@/store/modules/logs/actions";
import {
  ADD_SOURCE_LOG_FILTER,
  REMOVE_SOURCE_LOG_FILTER,
  SET_SOURCE_LOG_FILTERS,
  SET_DEFAULT_SOURCE_LOG_FILTERS,
} from "@/store/modules/persistentStorage/actions";

export default {
  name: "SourceLogSearch",
  components: {
    channelId: TextFilter,
    channelName: ChannelFilter,
    parsingType: EnumFilter,
    parsingStatus: StatusEnumFilter,
    errorCode: TextFilter,
    parsingDate: DateRangeFilter,
    proxyAddress: TextFilter,
  },
  data: function () {
    return {
      filtersReady: false,
      parsingTypes: [
        {
          key: "Article",
          item: "article",
        },
        {
          key: "Source",
          item: "source",
        },
      ],
      parsingStatus: [
        {
          key: "Error",
          item: "error",
        },
        {
          key: "Warning",
          item: "warning",
        },
        {
          key: "Success",
          item: "success",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("log", ["filters"]),
    ...mapGetters("persistentStorage", [
      "selectedSourceLogFilters",
      "availableSourceLogFilters",
    ]),

    availableFilters: function () {
      let vm = this;
      let result = [];

      const filtersToSelect = _.difference(
        _.keys(emptyFilters),
        this.selectedSourceLogFilters
      );

      filtersToSelect.forEach(function (filter) {
        result.push({
          key: filter,
          label: _.get(vm.availableSourceLogFilters, filter),
        });
      });
      return result;
    },
  },
  watch: {
    selectedSourceLogFilters: {
      handler: function () {
        let query = _.merge({}, this.$route.query);
        query["selectedFilters"] = _.merge([], this.selectedSourceLogFilters);
        query["page"] = this.$route.query.page;
        this.$router
          .push({ name: this.$route.name, query: {} })
          .catch(() => {});
        this.$router
          .push({ name: this.$route.name, query: query })
          .catch(() => {});
      },
      deep: true,
    },
  },
  methods: {
    addFilter: function (filter) {
      this.$store.dispatch(
        `persistentStorage/${ADD_SOURCE_LOG_FILTER}`,
        filter
      );
    },
    removeFilter: function (filter) {
      this.$store.dispatch(
        `persistentStorage/${REMOVE_SOURCE_LOG_FILTER}`,
        filter
      );
      this.filters[filter] = null;
    },
    resetFilters: function () {
      this.$store.dispatch(`log/${SET_EMPTY_FILTERS}`);
      this.$store.dispatch(
        `persistentStorage/${SET_DEFAULT_SOURCE_LOG_FILTERS}`
      );
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
  },
  mounted() {
    const vm = this;
    if (!_.isEmpty(vm.$route.query) && vm.$route.query["selectedFilters"]) {
      let selectedFilters = vm.$route.query["selectedFilters"];
      if (!(selectedFilters instanceof Array)) {
        selectedFilters = [selectedFilters];
      }
      this.$store.dispatch(
        `persistentStorage/${SET_SOURCE_LOG_FILTERS}`,
        selectedFilters
      );
    }
    if (!_.isEmpty(vm.$route.query)) {
      _.forIn(vm.$route.query, function (value, filter) {
        if (_.find(vm.availableFilters, ["key", filter])) {
          vm.addFilter(filter);
        }
      });
    }
    vm.filtersReady = true;
  },
};
</script>
